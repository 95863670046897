<nb-form-field>
  <input
    #autoInput
    id="autoInput"
    nbInput
    type="text"
    fullWidth
    shape="round"
    (input)="onChange()"
    (keyup)="inputKeyUp($event)"
    [placeholder]="placeholder"
    [nbAutocomplete]="auto" />
  <button *ngIf="icon !== undefined" nbSuffix nbButton ghost status="primary" (click)="submit()">
    <nb-icon [icon]="icon"> </nb-icon>
  </button>
</nb-form-field>

<nb-autocomplete #auto (selectedChange)="onSelectionChange($event)">
  <nb-option *ngFor="let option of filteredOptions" [value]="option">
    {{ option }}
  </nb-option>
</nb-autocomplete>
