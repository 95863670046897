import { Component, EventEmitter, Output } from '@angular/core';
import { IFileSettings } from 'src/app/interfaces/i-file-settings';
import { FileService } from 'src/app/services/file.service';
import { MappingService } from 'src/app/services/mapping.service';

@Component({
  selector: 'app-file-upload-mapping',
  templateUrl: './file-upload-mapping.component.html',
  styleUrls: ['./file-upload-mapping.component.scss'],
})
export class FileUploadMappingComponent {
  @Output() resetValidation: EventEmitter<boolean> = new EventEmitter();
  public error: string | undefined;
  public readonly MAX_ALLOWED_SIZE_MB = 5;
  public MAX_UNIQUE_TERMS: number;
  public uploadingFile = false;

  constructor(
    private fileService: FileService,
    public mappingService: MappingService
  ) {
    this.MAX_UNIQUE_TERMS = MappingService.MAX_UNIQUE_TERMS_FOR_COLUMN_MAPPING;
  }

  async onFileSelected(file: File) {
    this.error = undefined;
    if (file.size > this.MAX_ALLOWED_SIZE_MB * 1024 * 1024) {
      this.error = `File size is too large. File must be less than ${this.MAX_ALLOWED_SIZE_MB}MB`;
    } else {
      const fileData = await this.fileService.parseCsv(file).catch(error => (this.error = error));
      if (fileData.data.length === 0) {
        this.error = 'Unable to load columns. No columns found';
      } else {
        this.uploadingFile = true;
        this.mappingService.uploadFile(file).subscribe({
          next: result => {
            const fileSettings: IFileSettings = {
              fileData,
              fileName: result.file_name,
              fileUrl: result.file_url_path,
            };
            this.mappingService.fileSettings = fileSettings;
          },
          complete: () => {
            this.uploadingFile = false;
            this.resetValidation.emit(true);
          },
        });
      }
    }
  }
}
