import { Injectable } from '@angular/core';
import { IColumnTerms, IParsedFile } from '../interfaces/i-parsed-file';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  async parseCsv(file: File | null | undefined): Promise<IParsedFile> {
    if (file?.type === 'text/csv') {
      const csvString = await this.readCsv(file);
      const rows = this.getRows(csvString);
      const data: IColumnTerms[] = [];
      let headers: string[] = [];
      rows.forEach((row, rowIndex) => {
        const cells: string[] = row.split(',');
        if (rowIndex === 0) {
          headers = cells;
          headers.forEach(header => {
            data.push({
              columnHeader: header,
              terms: [],
              ontologies: [],
            });
          });
        } else {
          cells.forEach((cell, colIndex) => {
            if (colIndex < headers.length) {
              const currentTerms = data[colIndex].terms;
              if (currentTerms.indexOf(cell) === -1 && cell.length > 0) {
                data[colIndex].terms.push(cell);
              }
            }
          });
        }
      });
      return {
        data,
        totalTerms: this.getTotalTerms(data),
      };
    } else {
      return Promise.reject('File format not supported');
    }
  }

  private readCsv(file: File | null | undefined): Promise<string> {
    return new Promise((resolved, error) => {
      if (file) {
        const reader: FileReader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => {
          const csv: string = reader.result as string;
          resolved(csv);
        };
      } else {
        error('File is null or undefined');
      }
    });
  }

  private getRows(csvString: string): string[] {
    let delimitter = '\n';
    if (csvString.includes('\r\n')) {
      delimitter = '\r\n';
    }
    return csvString.split(delimitter);
  }

  private getTotalTerms(data: IColumnTerms[]): number {
    let totalTerms = 0;
    data.forEach(column => {
      totalTerms += column.terms.length;
    });
    return totalTerms;
  }
}
