<div class="white-fill padding-1 border-radius-1">
  <div class="header-1">Select Column containing Terms</div>
  <div class="flex-y width-fit margin-left-auto margin-top-1">
    <div class="margin-block-1">Column Header (total unique terms)</div>
    <ng-select [(ngModel)]="selectedColumnIndex" [clearable]="false" appearance="outline">
      <ng-option *ngFor="let column of file.data; let index = index" [value]="index">
        {{ column.columnHeader }} ({{ column.terms.length }})
      </ng-option>
    </ng-select>
    <div class="flex-x-reverse column-gap-1 margin-top-2">
      <button nbButton (click)="selectColumn()" shape="round" status="primary" size="small">select</button>
      <button nbButton (click)="close()" shape="round" status="primary" size="small" outline>cancel</button>
    </div>
  </div>
</div>
