import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { IOntologyTerm } from 'src/app/interfaces/i-ontology-term';

@Component({
  selector: 'app-ancestry-paths',
  templateUrl: './ancestry-paths.component.html',
  styleUrls: ['./ancestry-paths.component.scss'],
})
export class AncestryPathsComponent implements OnChanges {
  @Input() ancestryPaths!: IOntologyTerm[][];
  @Input() ontologyName!: string;
  @Input() queryTerm!: string;
  @Output() pathSelected: EventEmitter<IOntologyTerm[]> = new EventEmitter();

  sortedAncestryPaths!: IOntologyTerm[][];
  lastCommonAncestorIdx = -1;

  ngOnChanges() {
    this.sortedAncestryPaths = this.ancestryPaths.sort((a, b) => a.length - b.length);
    this.lastCommonAncestorIdx = this.getLastCommonAncestorIdx();
  }

  getLastCommonAncestorIdx(): number {
    const shortestPathLength = this.sortedAncestryPaths[0].length;
    const ancestorPathCount = this.sortedAncestryPaths.length;
    let index = -1;
    outerLoop: for (let i = 0; i < shortestPathLength; i++) {
      const currentCommonTerm = this.sortedAncestryPaths[0][i];
      for (let j = 0; j < ancestorPathCount; j++) {
        if (i < shortestPathLength - 1 && currentCommonTerm.iri !== this.sortedAncestryPaths[j][i].iri) {
          index = i - 1;
          break outerLoop;
        }
      }
    }
    return index;
  }
}
