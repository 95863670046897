<div class="grid-3-columns row-gap-1 column-gap-1">
  <div class="text-center">Term</div>
  <div></div>
  <div class="text-center">Mapped Term</div>
  <ng-container *ngFor="let index of [0, 1, 2, 3, 4, 5]">
    <div class="border-radius-1 height-1 width-3 dark-green-fill" [style]="'opacity:' + (index + 1) / 6"></div>
    <div><nb-icon icon="arrow-forward"></nb-icon></div>
    <div class="border-radius-1 height-1 width-3 primary-fill"></div>
  </ng-container>
</div>
