import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { IOntologyTerm } from 'src/app/interfaces/i-ontology-term';
import { ISearchResult } from 'src/app/interfaces/i-search-result';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnChanges {
  @Input() searchResult!: ISearchResult;
  @Input() queryTerm!: string;
  @Output() termSelected: EventEmitter<IOntologyTerm> = new EventEmitter();
  @Output() goToPage: EventEmitter<{ queryTerm: string; pageNumber: number }> = new EventEmitter();
  currentPage!: number;
  totalPages!: number;

  ngOnChanges() {
    this.currentPage = Math.floor(this.searchResult.current_offset / this.searchResult.current_limit) + 1;
    this.totalPages = Math.ceil(this.searchResult.total_results / this.searchResult.current_limit);
  }

  nextPage() {
    this.currentPage++;
    this.goToPage.emit({ queryTerm: this.queryTerm, pageNumber: this.currentPage });
  }

  prevPage() {
    this.currentPage--;
    this.goToPage.emit({ queryTerm: this.queryTerm, pageNumber: this.currentPage });
  }
}
