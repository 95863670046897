<div class="margin-inline-auto width-fit">
  <nb-card [nbSpinner]="uploadingFile" nbSpinnerStatus="primary">
    <nb-card-body>
      <div class="text-center">
        <div class="margin-bottom-1 header-2">Select <b>.csv</b> file to upload</div>
        <div class="text-small margin-top-1">Maximum Unique Terms: {{ MAX_UNIQUE_TERMS | number: '2.' }}</div>
        <div class="text-small margin-bottom-1">Maximum File Size: {{ MAX_ALLOWED_SIZE_MB }}MB</div>
        <app-file-upload (fileSelected)="onFileSelected($event)" fileFormat=".csv"></app-file-upload>
        <div class="text-small text-error">{{ error }}</div>
      </div>
    </nb-card-body>
  </nb-card>
  <div class="text-small text-center">
    <div *ngIf="uploadingFile">Uploading File ...</div>
    <div *ngIf="mappingService.fileSettings.fileName">{{ mappingService.fileSettings.fileName }} uploaded ✅</div>
  </div>
</div>
