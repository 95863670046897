import { formatDate } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IMappingOptions } from 'src/app/interfaces/i-mapping-options';
import { MappingService } from 'src/app/services/mapping.service';

@Component({
  selector: 'app-mapping-options',
  templateUrl: './mapping-options.component.html',
  styleUrls: ['./mapping-options.component.scss'],
})
export class MappingOptionsComponent implements OnInit, AfterViewInit {
  options!: IMappingOptions;
  public suggestionsPerOntology = Array.from(Array(20).keys());
  public simScoreThreshold: number;
  public sliderOptions = { floor: 0, ceil: 1, step: 0.01 };
  constructor(
    public mappingService: MappingService,
    private cdr: ChangeDetectorRef
  ) {
    this.simScoreThreshold = mappingService.mappingOptions.similarityScoreThreshold
      ? mappingService.mappingOptions.similarityScoreThreshold
      : mappingService.DEFAULT_SIMILARITY_SCORE_THRESHOLD;
  }

  ngOnInit() {
    this.options = Object.assign({}, this.mappingService.mappingOptions);
  }

  ngAfterViewInit(): void {
    if (this.options.jobTitle === undefined) {
      this.options.jobTitle = `job-${formatDate(Date.now(), 'yyyyMMdd-hh:mm', 'en-US')}`;
      this.saveNewSettings();
    }
    this.cdr.detectChanges();
  }

  updateSimScoreThreshold() {
    this.options.similarityScoreThreshold = this.simScoreThreshold;
    this.saveNewSettings();
  }

  saveNewSettings() {
    this.mappingService.mappingOptions = this.options;
  }
}
