import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { SelectColumnDialogComponent } from 'src/app/components/select-column-dialog/select-column-dialog.component';
import { MappingType } from 'src/app/enums/mapping-type';
import { IDataSettings } from 'src/app/interfaces/i-data-settings';
import { FileService } from 'src/app/services/file.service';
import { MappingService } from 'src/app/services/mapping.service';

@Component({
  selector: 'app-individual-terms-mapping',
  templateUrl: './individual-terms-mapping.component.html',
  styleUrls: ['./individual-terms-mapping.component.scss'],
})
export class IndividualTermsMappingComponent implements OnInit, AfterViewInit {
  mappingType!: MappingType;
  public settings!: IDataSettings;
  maxTermCount = MappingService.MAX_TERMS_COUNT;
  maxTermsError = false;
  uploadError: string | undefined;

  @ViewChild('termsInput') termsText: ElementRef | undefined;
  public MappingType = MappingType;

  constructor(
    public mappingService: MappingService,
    private fileService: FileService,
    private dialogService: NbDialogService
  ) {}

  ngOnInit() {
    this.mappingType = this.mappingService.type;
    this.settings = this.mappingService.dataSettings;
  }

  ngAfterViewInit() {
    if (this.termsText && this.termsText.nativeElement) {
      this.termsText.nativeElement.value = this.settings.terms.join('\n');
    }
  }

  onTermChange() {
    const termsListRaw = (this.termsText?.nativeElement.value as string).split('\n');
    const termsList: string[] = [];
    termsListRaw.forEach(term => {
      term.trim();
      if (term.length !== 0 && term.replace(/\s/g, '').length !== 0) {
        termsList.push(term);
      }
    });
    this.settings.terms = termsList;
    this.saveNewSettings();
  }

  async onFileSelected(file: File) {
    this.uploadError = undefined;
    try {
      const parsedFile = await this.fileService.parseCsv(file);
      if (parsedFile.data.length === 1) {
        this.settings.terms = [parsedFile.data[0].columnHeader].concat(parsedFile.data[0].terms);
      } else if (parsedFile.totalTerms === 0 && parsedFile.data.length > 0) {
        // This is a csv file with just 1 row and multiple columns
        this.settings.terms = [];
        parsedFile.data.forEach(column => {
          this.settings.terms.push(column.columnHeader);
        });
      } else {
        this.dialogService
          .open(SelectColumnDialogComponent, {
            context: {
              file: parsedFile,
            },
          })
          .onClose.subscribe(() => {
            this.saveNewSettings();
            this.ngAfterViewInit();
          });
      }
      this.saveNewSettings();
      this.ngAfterViewInit();
    } catch (e) {
      this.uploadError = e as string;
    }
  }

  saveNewSettings() {
    if (this.settings.terms.length > this.maxTermCount) {
      this.maxTermsError = true;
    } else {
      this.maxTermsError = false;
    }
    this.mappingService.dataSettings = this.settings;
  }
}
