import { Injectable } from '@angular/core';
import { IMappingPayload } from '../interfaces/i-terminology-mapping-payload';
import { MappingType } from '../enums/mapping-type';
import { IMappingOptions } from '../interfaces/i-mapping-options';
import { IDataSettings } from '../interfaces/i-data-settings';
import { firstValueFrom } from 'rxjs';
import { IColumnTerms, IParsedFile } from '../interfaces/i-parsed-file';
import { ApiService } from './api.service';
import { MappingService } from './mapping.service';
import { OntologyService } from './ontology.service';

@Injectable({
  providedIn: 'root',
})
export class ReRunMappingService {
  public allUnavailableOntologies: string[] = [];
  constructor(
    private api: ApiService,
    private mappingService: MappingService,
    private ontologyService: OntologyService
  ) {}

  async loadSettings(taskId: string, payload: IMappingPayload) {
    this.allUnavailableOntologies = [];
    this.loadCommonSettings(payload);
    if (payload.terms_input_type === MappingType.INDIVIDUAL_TERMS) {
      this.loadIndividualTermsSettings(payload);
    } else if (payload.terms_input_type === MappingType.UPLOAD_FILE) {
      await this.loadFileColumnTermsSettings(taskId, payload);
    } else {
      console.error('Unknown file type. Cannot load Task');
    }
  }

  private loadCommonSettings(payload: IMappingPayload) {
    this.mappingService.type = payload.terms_input_type;
    const newOptions: IMappingOptions = {
      mappingMethodology: payload.task_name,
      maxResultsPerOntology: payload.params.limit
        ? payload.params.limit
        : this.mappingService.DEFAULT_MAX_RESULTS_PER_ONTOLOGY,
      jobTitle: payload.label ? payload.label : '',
      similarityScoreThreshold: payload.params.similarity_score_threshold
        ? payload.params.similarity_score_threshold
        : this.mappingService.DEFAULT_SIMILARITY_SCORE_THRESHOLD,
      caseSensitive: payload.params.case_sensitive,
    };
    this.mappingService.mappingOptions = newOptions;
  }

  private loadIndividualTermsSettings(payload: IMappingPayload) {
    const termsList: string[] = [];
    const ontologyList: string[][] = [];
    payload.params.terms?.forEach(term => {
      termsList.push(term.term);
      const verifiedOntologyList = this.verifyOntologyList(term.ontologies);
      ontologyList.push(verifiedOntologyList);
    });
    const newSettings: IDataSettings = {
      terms: termsList,
    };
    this.mappingService.dataSettings = newSettings;
    this.mappingService.settings.forEach((value, index) => {
      value.ontologies = ontologyList[index];
    });
  }

  private async loadFileColumnTermsSettings(taskId: string, payload: IMappingPayload) {
    const metadata = await firstValueFrom(this.api.getTaskRequestInputFileMetadata(taskId));
    if (metadata) {
      const columns: IColumnTerms[] = [];
      let totalTerms = 0;
      metadata.file_columns.forEach(column => {
        const selectedColumn = payload.params?.term_columns?.find(sCol => sCol.column_header === column.column_name);
        const colTermsCount = column.num_unique_values;
        totalTerms += colTermsCount;
        columns.push({
          columnHeader: column.column_name,
          terms: new Array<string>(colTermsCount),
          ontologies: selectedColumn ? this.verifyOntologyList(selectedColumn.ontologies) : [],
        });
      });
      const file: IParsedFile = {
        data: columns,
        totalTerms,
      };
      this.mappingService.fileSettings = {
        fileData: file,
        fileName: metadata.file_name,
        fileUrl: metadata.file_url_path,
      };
    }
  }

  private verifyOntologyList(incomingOntologies: string[]): string[] {
    const availableIncomingOnotologies: string[] = incomingOntologies.filter(o =>
      this.ontologyService.availableOntologyNames.includes(o)
    );
    const unavailableOnotologies: string[] = incomingOntologies.filter(
      o => !this.ontologyService.availableOntologyNames.includes(o)
    );
    unavailableOnotologies.forEach(o => {
      if (this.allUnavailableOntologies.indexOf(o) === -1) {
        this.allUnavailableOntologies.push(o);
      }
    });
    return availableIncomingOnotologies;
  }
}
