<div class="flex-x column-gap-1">
  <div>
    <div class="grid-3-columns border-bottom-black column-gap-1">
      <div class="text-center text-small">Column 1<br />to Map</div>
      <div class="text-center text-small">Column 2<br />to Map</div>
      <div class="text-center text-small">Column 3<br />to Map</div>
    </div>
    <div class="grid-3-columns row-gap-1 margin-top-1 column-gap-1">
      <ng-container *ngFor="let index of [0, 1, 2, 3, 4, 5]">
        <div
          class="border-radius-1 height-1 width-full dark-green-fill text-center"
          [style]="'opacity:' + (index + 1) / 6"></div>
        <div
          class="border-radius-1 height-1 width-full dark-blue-fill text-center"
          [style]="'opacity:' + (index + 1) / (6 * 3)"></div>
        <div
          class="border-radius-1 height-1 width-full dark-red-fill text-center"
          [style]="'opacity:' + (index + 1) / 6"></div>
      </ng-container>
    </div>
  </div>
  <div class="text-center"><nb-icon icon="arrow-forward"></nb-icon></div>
  <div>
    <div class="grid-3-columns border-bottom-black column-gap-1">
      <div class="text-center text-small">Column 1<br />Header</div>
      <div class="text-center text-small">Column 2<br />Header</div>
      <div class="text-center text-small">Column 3<br />Header</div>
    </div>
    <div class="grid-3-columns margin-top-1 row-gap-1 column-gap-1">
      <ng-container *ngFor="let index of [0, 1, 2, 3, 4, 5]">
        <div class="border-radius-1 height-1 width-full primary-fill text-center"></div>
        <div class="border-radius-1 height-1 width-full medium-gray-fill text-center"></div>
        <div class="border-radius-1 height-1 width-full dark-gray-fill text-center"></div>
      </ng-container>
    </div>
  </div>
</div>
