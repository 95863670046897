<div
  [nbSpinner]="loading"
  nbSpinnerStatus="primary"
  nbSpinnerMessage=""
  class="white-fill padding-1 border-radius-1 width-fit">
  <div class="header-1">
    <div *ngIf="!error">Add New Mapping Rule?</div>
    <div *ngIf="error" class="header-1">Error Adding new mapping rule ❌</div>
  </div>
  <div class="margin-top-2">
    Ontology: <b>{{ (newRule ? newRule.ontology_name : ontology ? ontology.ontology_name : '') | uppercase }}</b>
  </div>
  <div *ngIf="newRule" class="grid-3-columns column-gap-1 margin-top-2 margin-bottom-3 text-center width-4">
    <div>
      <div>Query Term</div>
      <div>
        <b>{{ newRule.query_term }}</b>
      </div>
    </div>
    <div><nb-icon icon="arrow-forward" status="primary" style="font-size: xx-large"></nb-icon></div>
    <div>
      <div>Maps To</div>
      <div>
        <b>{{ newRule.label }}</b>
      </div>
    </div>
  </div>
  <div *ngIf="ontology" class="grid-3-columns column-gap-1 margin-top-2 margin-bottom-3 text-center">
    <div>
      <div>Query Term</div>
      <div>
        <input type="text" [(ngModel)]="newTerm" nbInput (input)="verifyInputs()" placeholder="Enter input term" />
      </div>
    </div>
    <div><nb-icon icon="arrow-forward" status="primary" style="font-size: xx-large"></nb-icon></div>
    <div>
      <div>Maps To</div>
      <div>
        <input
          type="text"
          [(ngModel)]="newLabelOrCurie"
          nbInput
          (input)="verifyInputs()"
          placeholder="Enter term LABEL or CURIE" />
      </div>
    </div>
  </div>
  <div *ngIf="error" class="text-center text-error">
    An Error Occurred. <b>{{ error }}</b>
  </div>
  <div class="flex-x-reverse column-gap-1 margin-top-1">
    <button
      *ngIf="!error"
      nbButton
      (click)="addRule()"
      shape="round"
      status="primary"
      size="small"
      [disabled]="!canAddRule">
      Add Rule
    </button>
    <button nbButton (click)="close()" shape="round" status="primary" size="small" outline>dismiss</button>
  </div>
</div>
