<div class="width-fit min-width-5 margin-inline-auto">
  <div class="row-gap-1 column-gap-1 grid-3-columns">
    <div *ngIf="mappingService.type === 'individual'" class="text-center header-1">Term</div>
    <div *ngIf="mappingService.type === 'filecolumns'" class="text-center header-1">
      Column Header<br />(Total Unique Terms)
    </div>
    <div *ngIf="mappingService.type === 'filecolumns'" class="text-center header-1">Include In Mapping</div>
    <div class="text-center header-1">Map to Output</div>
    <div *ngIf="mappingService.type === 'individual'" class="text-center header-1">Specify Parent class (optional)</div>
    <ng-container *ngFor="let term of visibleTerms; let index = index">
      <div class="text-center" [class.text-selected]="mappingService.type === 'filecolumns' && term.includeInMapping">
        {{ term.termOrColumnName }} {{ term.uniqueTermsInColumnCount ? '(' + term.uniqueTermsInColumnCount + ')' : '' }}
      </div>
      <div *ngIf="mappingService.type === 'filecolumns'" class="text-center">
        <nb-checkbox [(checked)]="term.includeInMapping" (checkedChange)="onSelectedChange()"></nb-checkbox>
      </div>
      <div class="margin-inline-auto margin-block-auto">
        <ng-select
          [(ngModel)]="term.ontologies"
          [multiple]="true"
          [disabled]="isInputDisabled(index)"
          (change)="onSelectedChange(term)"
          [placeholder]="
            term.includeInMapping
              ? 'Select Ontologies' + (mappingService.type === 'filecolumns' ? ' (max 3)' : '')
              : '--'
          "
          appearance="outline"
          [maxSelectedItems]="mappingService.type === 'filecolumns' ? 3 : ontologyService.availableOntologies.length">
          <ng-option *ngFor="let ontology of ontologyService.availableOntologies" [value]="ontology.ontology_name">
            {{ ontology.ontology_name }}
          </ng-option>
          <ng-template *ngIf="mappingService.type === 'individual'" ng-footer-tmp>
            <div class="text-center">
              <button nbButton status="primary" shape="round" outline size="tiny" (click)="onSelectAll(index)">
                Select All
              </button>
              <button
                nbButton
                status="primary"
                shape="round"
                outline
                size="tiny"
                (click)="onClearOntologies(index)"
                class="margin-left-1">
                Clear All
              </button>
            </div>
          </ng-template>
        </ng-select>
        <div>
          <nb-checkbox
            class="margin-block-auto"
            *ngIf="showSameOntologyForEachCheckbox(index)"
            [(checked)]="mappingService.applySameOntologiesForEachTerm"
            (checkedChange)="onSelectedChange()">
            Select this Ontology for all
          </nb-checkbox>
        </div>
      </div>
      <ng-container *ngIf="mappingService.type === 'individual'">
        <div *ngIf="term.ontologies.length === 0" class="text-center text-small">
          First, select an Ontology to filter results <br />by parent class (optional)
        </div>
        <div *ngIf="term.ontologies.length > 1" class="text-center text-small">
          Multiple Ontologies selected. Parent class filter<br />is limited to 1 ontology per term (optional)
        </div>
        <ng-container *ngIf="term.ontologies.length === 1">
          <div class="ng-select-hide-down-carat">
            <ng-select
              *ngIf="
                ontologyService.getOntologyFromName(term.ontologies[0]).top_level_parents_count > 0;
                else noHierarchyInOntology
              "
              [(ngModel)]="term.parentClassIris"
              [multiple]="true"
              [disabled]="term.ontologies.length !== 1"
              [placeholder]="'Search ' + term.ontologies[0]"
              [notFoundText]="'Type to search for Parent Class'"
              (search)="onParentClassTextChange($event.term, term.ontologies[0])"
              appearance="outline">
              <ng-container
                *ngFor="
                  let parentClass of ontologyParentClassesLookup ? ontologyParentClassesLookup[term.ontologies[0]] : []
                ">
                <ng-option
                  *ngIf="parentClass.label.toLowerCase().includes(parentClassSearchText.toLowerCase())"
                  [value]="parentClass.iri"
                  >{{ parentClass.label }}
                </ng-option>
              </ng-container>
            </ng-select>
          </div>
          <ng-template #noHierarchyInOntology>
            <div class="text-center margin-top-1 text-small">
              {{ term.ontologies[0] }} does not have Hierarchy.<br />Cannot use (optional) Parent Class filter.
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <igx-paginator
    *ngIf="totalTerms > currentItemsPerPage"
    [selectOptions]="[ITEMS_PER_PAGE, ITEMS_PER_PAGE * 2]"
    [(perPage)]="currentItemsPerPage"
    class="margin-top-1"
    [totalRecords]="totalTerms"
    [(page)]="currentPage"
    (pageChange)="onPageChange()"
    (perPageChange)="onPageChange()"></igx-paginator>
</div>
