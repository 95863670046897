<div [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerMessage="" class="white-fill padding-1 border-radius-1">
  <div class="header-1" *ngIf="fileName">{{ fileName }}</div>
  <div>
    <span>Download {{ mappingMethodology | uppercase }} mapping</span>
    <span *ngIf="totalTerms > 0">&nbsp;of {{ totalTerms }} term{{ totalTerms === 1 ? '' : 's' }}</span>
  </div>
  <div *ngIf="!error" class="grid-2-columns column-gap-1 margin-top-2 row-gap-1">
    <div>Include in Output</div>
    <div class="flex-y width-fit margin-left-auto">
      <nb-checkbox [(checked)]="allColumnsChecked" (checkedChange)="onColumnsUpdate()">All</nb-checkbox>
      <nb-checkbox
        *ngFor="let header of columns"
        [(checked)]="header.included"
        (checkedChange)="onColumnsUpdate($event)">
        {{ header.label | titlecase }}
      </nb-checkbox>
    </div>
    <ng-container *ngIf="multipleFileFormats">
      <div class="margin-block-auto">Output Format</div>
      <ng-select [(ngModel)]="fileFormat" class="small" [clearable]="false" appearance="outline">
        <ng-option [value]="'csv'">CSV</ng-option>
        <ng-option [value]="'xml'">XML</ng-option>
        <ng-option [value]="'json'">JSON</ng-option>
      </ng-select>
    </ng-container>
  </div>
  <div *ngIf="error" class="text-center margin-top-1">
    <div>❌</div>
    An Error Occurred. Please contact Support
  </div>
  <div class="flex-x-reverse column-gap-1 margin-top-2">
    <button *ngIf="!error" nbButton (click)="download()" shape="round" status="primary" size="small">download</button>
    <button nbButton (click)="close()" shape="round" status="primary" size="small" outline>cancel</button>
  </div>
</div>
