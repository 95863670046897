import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { IOntology } from 'src/app/interfaces/i-ontology';
import { KeycloakService } from 'src/app/services/keycloak.service';
import { OntologyService } from 'src/app/services/ontology.service';

@Component({
  selector: 'app-add-custom-ontology-dialog',
  templateUrl: './add-custom-ontology-dialog.component.html',
  styleUrls: ['./add-custom-ontology-dialog.component.scss'],
})
export class AddCustomOntologyDialogComponent implements OnInit, AfterViewInit {
  @Input() ontology: IOntology | undefined;
  public visibility = 'private';
  public loading = false;
  public nameError = false;
  public errorMsg: string | undefined;
  @ViewChild('ontologyName') ontologyName: ElementRef | undefined;
  @ViewChild('ontologyVersion') ontologyVersion: ElementRef | undefined;
  name = '';
  version = '';
  constructor(
    private dialogRef: NbDialogRef<unknown>,
    private ontologyService: OntologyService,
    public keycloakService: KeycloakService
  ) {}

  ngOnInit(): void {
    if (this.ontology) {
      this.name = this.ontology.ontology_name;
      this.version = this.ontology.ontology_version;
      this.visibility = this.ontology.visibility;
    }
  }

  ngAfterViewInit(): void {
    if (this.ontologyName) {
      this.ontologyName.nativeElement.value = this.name;
    }
    if (this.ontologyVersion) {
      this.ontologyVersion.nativeElement.value = this.version;
    }
  }

  updateValues() {
    this.resetErrors();
    this.name = this.ontologyName?.nativeElement.value;
    this.version = this.ontologyVersion?.nativeElement.value;
    if (this.ontology) {
      this.ontology.ontology_name = this.name;
      this.ontology.ontology_version = this.version;
      this.ontology.visibility = this.visibility;
    }
  }

  close(updated = false) {
    this.dialogRef.close({ updated });
  }

  canCreateNewOntology(): boolean {
    const name = this.ontologyName?.nativeElement.value;
    const version = this.ontologyVersion?.nativeElement.value;
    return name && name.length > 0 && version && version.length > 0;
  }

  createOrUpdate() {
    const endpoint = this.ontology
      ? this.ontologyService.update(this.ontology)
      : this.ontologyService.create(this.name, this.version, this.visibility);
    if (this.canCreateNewOntology()) {
      this.loading = true;
      endpoint.subscribe({
        next: () => this.close(true),
        error: err => {
          this.loading = false;
          if (err.status >= 500 || err.status === 0) {
            this.close();
          } else if (err.status === 409) {
            this.nameError = true;
          } else if (err.status === 403) {
            this.errorMsg = 'You are not allowed to Edit this ontology.';
          } else {
            this.errorMsg = err.statusText;
          }
        },
        complete: () => (this.loading = false),
      });
    }
  }

  private resetErrors() {
    this.errorMsg = undefined;
    this.nameError = false;
  }
}
