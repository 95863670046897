import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MappingType } from 'src/app/enums/mapping-type';
import { MappingService } from 'src/app/services/mapping.service';

@Component({
  selector: 'app-dataset-settings',
  templateUrl: './data-settings.component.html',
  styleUrls: ['./data-settings.component.scss'],
})
export class DataSettingsComponent implements OnInit {
  @Output() resetValidation: EventEmitter<boolean> = new EventEmitter();
  mappingType!: MappingType;
  public MappingType = MappingType;

  constructor(public mappingService: MappingService) {}

  ngOnInit() {
    this.mappingType = this.mappingService.type;
  }
}
