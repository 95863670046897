<div class="flex-x width-full">
  <div class="margin-top-1 clickable margin-right-auto" routerLink="/">
    <img src="assets/images/logo.png" width="200px" />
  </div>
  <div class="margin-block-auto margin-left-1">👤 {{ keyCloakService.getUsername() }}</div>
  <div class="margin-block-auto margin-left-1">
    <button shape="round" status="primary" outline nbButton routerLink="manage-ontologies">
      <nb-icon icon="layers-outline"></nb-icon>
      View Ontologies
    </button>
  </div>
  <div class="margin-block-auto margin-left-1">
    <button shape="round" status="primary" outline (click)="logout()" nbButton>
      <nb-icon icon="log-out"></nb-icon>Logout
    </button>
  </div>
</div>
