<nb-card [nbSpinner]="loading" nbSpinnerStatus="primary">
  <nb-card-header class="border-none">
    <div class="flex-between">
      <div class="header-1">Ontology: {{ ontology ? ontology.ontology_name : '...' }}</div>
      <div class="width-4 text-small height-2">
        <app-input-with-suggestions
          icon="search-outline"
          placeholder="Search Term"
          [options]="searchSuggestions"
          (textChange)="onSearchTextChange($event)"
          (submitClicked)="searchTerm($event)"
          (suggestionSelected)="searchTerm($event)">
        </app-input-with-suggestions>
        <div class="text-error text-small text-center">{{ searchError }}</div>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div [hidden]="searchResult || ancestryPaths" id="chart" class="width-full height-64vh"></div>
    <app-search-results
      *ngIf="searchResult"
      [searchResult]="searchResult!"
      [queryTerm]="searchQuery!"
      (termSelected)="getAncestry($event)"
      (goToPage)="showSearchPage($event)">
    </app-search-results>
    <app-ancestry-paths
      *ngIf="ancestryPaths"
      [ancestryPaths]="ancestryPaths!"
      [ontologyName]="ontology.ontology_name"
      [queryTerm]="focusTerm!.label"
      (pathSelected)="showPath($event)">
    </app-ancestry-paths>
    <div *ngIf="focusTermError" class="text-error width-fit margin-inline-auto flex-x column-gap-1">
      <div>{{ focusTermError }}</div>
      <button nbButton size="tiny" outline (click)="reset()" status="primary" shape="round">Reset</button>
    </div>
  </nb-card-body>
</nb-card>
