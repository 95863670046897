<div>
  <button
    nbButton
    status="primary"
    size="tiny"
    outline
    [disabled]="disabled"
    (click)="fileUpload.click()"
    shape="round">
    Browse
  </button>
  <input type="file" class="no-display" [accept]="fileFormat" (change)="onFileSelected($event)" #fileUpload />
</div>
