import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  @Input() disabled = false;
  @Input() fileFormat = '';
  @Output() fileSelected: EventEmitter<File> = new EventEmitter();

  async onFileSelected(e: Event) {
    const files = (<HTMLInputElement>e.target).files;
    if (files) {
      const file: File | null = files.item(0);
      if (file) {
        this.fileSelected.emit(file);
      }
    }
  }
}
