import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { IParsedFile } from 'src/app/interfaces/i-parsed-file';
import { MappingService } from 'src/app/services/mapping.service';

@Component({
  selector: 'app-select-column-dialog',
  templateUrl: './select-column-dialog.component.html',
  styleUrls: ['./select-column-dialog.component.scss'],
})
export class SelectColumnDialogComponent {
  @Input() file!: IParsedFile;

  selectedColumnIndex = 0;

  constructor(
    private dialogRef: NbDialogRef<unknown>,
    private mappingService: MappingService
  ) {}

  selectColumn() {
    const selectedTerms: string[] = this.file.data[this.selectedColumnIndex].terms;
    this.mappingService.dataSettings.terms = selectedTerms;
    this.close();
  }

  close() {
    this.dialogRef.close();
  }
}
