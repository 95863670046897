import { KeycloakConfig } from 'keycloak-js';

const keycloakConfig: KeycloakConfig = {
  url: 'https://keycloak2.rbsapp.net/',
  realm: 'RANCHOBIOSCIENCES',
  clientId: 'TMS-LOGIN',
};

export const environment = {
  prod: false,
  keycloak: keycloakConfig,
  baseUrl: 'https://dev-tms.rbsapp.net/api/',
};
