import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, Subscription, concatMap, map } from 'rxjs';
import { IOntology } from '../interfaces/i-ontology';
import { KeycloakService } from './keycloak.service';
import { IOntologyFileUploadResponse } from '../interfaces/i-ontology-file-upload-response';
import { ITmsRule } from '../interfaces/i-tms-rule';
import { IOntologyTerm } from '../interfaces/i-ontology-term';
import { ISearchResult } from '../interfaces/i-search-result';

@Injectable({
  providedIn: 'root',
})
export class OntologyService {
  private _availableOntologies: IOntology[] = [];
  private initializedSubject = new BehaviorSubject<boolean>(false);
  initialized$ = this.initializedSubject.asObservable();
  constructor(
    private api: ApiService,
    private keycloakService: KeycloakService
  ) {
    this.initializedSubject.next(false);
    this.fetchOntologies();
  }

  public fetchOntologies() {
    const sub: Subscription = this.api.getOntologies().subscribe({
      next: response => {
        const ontologies = response.result;
        if (ontologies.length === 0) {
          this.initializedSubject.error('No Ontologies Found');
        } else {
          this._availableOntologies = ontologies.sort((a, b) => a.ontology_name.localeCompare(b.ontology_name));
          this.initializedSubject.next(true);
        }
      },
      error: err => {
        this.initializedSubject.error(err);
      },
      complete: () => sub.unsubscribe(),
    });
  }

  get availableOntologies(): IOntology[] {
    return this._availableOntologies;
  }

  get availableOntologyNames(): string[] {
    return this._availableOntologies.map(ontology => ontology.ontology_name);
  }

  getOntologyFromName(ontologyName: string): IOntology {
    const matches = this._availableOntologies.filter(x => x.ontology_name === ontologyName);
    if (matches.length !== 1) {
      console.error('Found more than 1 match for ontology name. Should not happen');
    }
    return matches[0];
  }

  getEditableOntologies(): IOntology[] {
    if (this.keycloakService.canManageOntologies()) {
      return this.availableOntologies.filter(o => o.visibility === 'private');
    } else {
      return [];
    }
  }

  create(name: string, version: string, visibility: string): Observable<IOntology> {
    return this.api.postOntology(name, version, visibility);
  }

  delete(ontology: IOntology): Observable<unknown> {
    return this.api.deleteOntology(ontology.ontology_id);
  }

  update(ontology: IOntology): Observable<IOntology> {
    return this.api.putOntology(
      ontology.ontology_id,
      ontology.ontology_name,
      ontology.ontology_version,
      ontology.visibility
    );
  }

  uploadOwlFile(ontology: IOntology, file: File): Observable<IOntologyFileUploadResponse> {
    return this.api.uploadFile(file).pipe(
      concatMap(fileUploadResponse => {
        return this.api.uploadOwlFile(ontology.ontology_id, fileUploadResponse.file_url_path);
      })
    );
  }

  getUploadOwlFileStatus(id: string): Observable<IOntologyFileUploadResponse | undefined> {
    return this.api.getUploadOwlFileStatus(id);
  }

  getOntology(id: string): IOntology | undefined {
    return this._availableOntologies.find(o => o.ontology_id === id);
  }

  getChildrenOfTerm(ontologyId: string, iri: string, offset = 0, limit = 1000): Observable<ISearchResult> {
    return this.api.getChildren(ontologyId, iri, offset, limit).pipe(
      map(response => {
        response.result = response.result.sort((a, b) => a.label.localeCompare(b.label));
        return response;
      })
    );
  }

  getAncestors(ontologyId: string, termIri: string): Observable<IOntologyTerm[][]> {
    return this.api.getAncestors(ontologyId, termIri);
  }

  getMappingRules(ontologyId: string): Observable<ITmsRule[]> {
    return this.api.getMappingRules(ontologyId);
  }

  searchTerm(
    ontologyId: string,
    labelOrCurie: string,
    offset = 0,
    limit = 1000,
    parents_only = false
  ): Observable<ISearchResult> {
    return this.api.searchTerm(ontologyId, labelOrCurie, parents_only, offset, limit);
  }
}
