import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { AddMappingRuleDialogComponent } from 'src/app/components/add-mapping-rule-dialog/add-mapping-rule-dialog.component';
import { DeleteTmsRuleDialogComponent } from 'src/app/components/delete-tms-rule-dialog/delete-tms-rule-dialog.component';
import { IOntology } from 'src/app/interfaces/i-ontology';
import { ITmsRule } from 'src/app/interfaces/i-tms-rule';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { OntologyService } from 'src/app/services/ontology.service';

@Component({
  selector: 'app-ontology-mapping-rules',
  templateUrl: './ontology-mapping-rules.component.html',
  styleUrls: ['./ontology-mapping-rules.component.scss'],
})
export class OntologyMappingRulesComponent implements OnInit {
  loading = false;
  ontology!: IOntology;
  rules: ITmsRule[] | undefined;
  error = false;

  constructor(
    public ontologyService: OntologyService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: NbDialogService,
    private analytics: AnalyticsService
  ) {}

  private exitPage() {
    this.router.navigate(['manage-ontologies']);
  }

  ngOnInit() {
    this.loading = true;
    this.ontologyService.initialized$.subscribe({
      next: async initialized => {
        if (initialized) {
          const currentId = this.route.snapshot.paramMap.get('id');
          if (currentId === null) {
            this.exitPage();
            return;
          }
          const ontology = this.ontologyService.getOntology(currentId);
          if (ontology === undefined) {
            this.exitPage();
            return;
          }
          this.ontology = ontology;
          this.getAllRules();
        }
      },
      error: () => {
        this.loading = false;
        this.exitPage();
      },
    });
  }

  addRule() {
    this.analytics.trackEvent(
      'mapping_rule_add_click',
      'User Clicked add a new mapping rule from the mapping rules page',
      'mapping_rule'
    );
    this.dialogService
      .open(AddMappingRuleDialogComponent, {
        context: {
          ontology: this.ontology,
        },
      })
      .onClose.subscribe({
        next: result => {
          if (result?.ruleAdded) {
            this.getAllRules();
            this.analytics.trackEvent(
              'mapping_rule_added_from_mapping_rules_page',
              'User added a new mapping rule from the mapping rules page',
              'mapping_rule'
            );
          }
        },
      });
  }

  deleteRule(tmsRule: ITmsRule) {
    this.analytics.trackEvent(
      'mapping_rule_delete_click',
      'User Clicked Delete a mapping rule from the mapping rules page',
      'mapping_rule'
    );
    this.dialogService
      .open(DeleteTmsRuleDialogComponent, {
        context: {
          tmsRule,
        },
      })
      .onClose.subscribe({
        next: result => {
          if (result?.updated === true) {
            this.getAllRules();
            this.analytics.trackEvent(
              'mapping_rule_deleted_from_mapping_rules_page',
              'User deleted a mapping rule from the mapping rules page',
              'mapping_rule'
            );
          }
        },
      });
  }

  private getAllRules() {
    this.loading = true;
    this.ontologyService.getMappingRules(this.ontology.ontology_id).subscribe({
      next: results => {
        this.rules = results;
        this.loading = false;
      },
      error: () => {
        this.error = true;
        this.loading = false;
      },
    });
  }
}
