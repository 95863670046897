<div class="width-5 margin-inline-auto">
  <nb-stepper #stepper [selectedIndex]="stepIndex" disableStepNavigation linear>
    <nb-step [label]="'Input Type'" [stepControl]="getStepCompleted(0)"></nb-step>
    <nb-step [label]="'Data Input'" [stepControl]="getStepCompleted(1)"></nb-step>
    <nb-step [label]="'Select Ontology'" [stepControl]="getStepCompleted(2)"></nb-step>
    <nb-step [label]="'Options'" [stepControl]="getStepCompleted(3)"></nb-step>
    <nb-step [label]="'Review & Download'" [stepControl]="getStepCompleted(4)"></nb-step>
  </nb-stepper>
</div>
<ng-container *ngIf="initError === undefined">
  <div *ngIf="ready">
    <app-choose-mapping-type
      *ngIf="stepIndex === MappingSteps.SELECT_INPUT_TYPE"
      (selectedType)="nextStep()"></app-choose-mapping-type>
    <app-dataset-settings
      *ngIf="stepIndex === MappingSteps.SELECT_DATA_SETTINGS"
      (resetValidation)="validate()"></app-dataset-settings>
    <ng-container *ngIf="stepIndex === MappingSteps.SELECT_ONTOLOGY">
      <div *ngIf="rerunUnavailableOntologies" class="text-center text-error margin-bottom-1">
        Unable to load the following ontologies, they may have been deleted: <br />
        <b>{{ rerunUnavailableOntologies }}</b> <br />
      </div>
      <app-mapping-settings></app-mapping-settings>
    </ng-container>
    <app-mapping-options *ngIf="stepIndex === MappingSteps.MAPPING_OPTIONS"></app-mapping-options>
    <app-submit-and-review
      *ngIf="stepIndex === MappingSteps.REVIEW"
      (mappingComplete)="onMappingComplete($event)"
      (mappingCancelled)="onMappingCancelled($event)"></app-submit-and-review>
    <div class="margin-top-2">
      <div class="text-center text-error" *ngIf="error">{{ error }}</div>
      <div class="width-fit flex-x column-gap-2 margin-inline-auto">
        <button
          *ngIf="!(stepIndex === MappingSteps.REVIEW && !mappingComplete)"
          nbButton
          shape="round"
          (click)="prevStep()">
          Back
        </button>
        <button
          *ngIf="stepIndex === MappingSteps.SELECT_DATA_SETTINGS || stepIndex === MappingSteps.SELECT_ONTOLOGY"
          nbButton
          status="primary"
          shape="round"
          (click)="nextStep()">
          Next Step
        </button>
        <button
          *ngIf="stepIndex === MappingSteps.MAPPING_OPTIONS"
          nbButton
          status="primary"
          shape="round"
          (click)="nextStep()">
          Start Mapping
        </button>
        <button
          *ngIf="stepIndex === MappingSteps.REVIEW && mappingComplete"
          nbButton
          status="primary"
          shape="round"
          (click)="reset()">
          Start Over
        </button>
      </div>
    </div>
  </div>
  <nb-card [nbSpinner]="!ready" nbSpinnerStatus="primary" nbSpinnerMessage="Initializing ..." class="border-none">
    <div *ngIf="!ready" class="height-3"></div>
  </nb-card>
</ng-container>
<div *ngIf="initError" class="text-center margin-top-1">
  <div>❌</div>
  <div>An Error Occurred. Please contact Support</div>
  <div class="text-small margin-top-1">
    Error: <b>{{ initError }}</b>
  </div>
</div>
