<div class="width-5 margin-inline-auto">
  <div class="flex-between">
    <div class="header-2 width-fit">
      Found <b>{{ searchResult.total_results }}</b> results
    </div>
    <div [hidden]="totalPages === 1" class="flex-x column-gap-1 width-fit">
      <button nbButton size="tiny" [hidden]="currentPage === 1" status="primary" ghost (click)="prevPage()">
        Prev
      </button>
      <div class="margin-block-auto">
        Page <b>{{ currentPage }}</b> of <b>{{ totalPages }}</b>
      </div>
      <button nbButton size="tiny" [hidden]="currentPage === totalPages" status="primary" ghost (click)="nextPage()">
        Next
      </button>
    </div>
  </div>
  <nb-card *ngFor="let result of searchResult.result">
    <nb-card-body class="flex-between align-items-center">
      <div>
        <div>
          Label: <b>{{ result.label }}</b>
        </div>
        <div>
          Curie: <b>{{ result.curie }}</b>
        </div>
        <div>
          Iri: <a [href]="result.iri" target="_blank">{{ result.iri }}</a>
        </div>
      </div>
      <div>
        <button nbButton status="primary" (click)="termSelected.emit(result)" shape="round" size="small">
          Show Ancestry<nb-icon icon="arrowhead-right-outline"></nb-icon>
        </button>
      </div>
    </nb-card-body>
  </nb-card>
  <div class="flex-x-reverse">
    <div [hidden]="totalPages === 1" class="flex-x column-gap-1 width-fit">
      <button nbButton size="tiny" [hidden]="currentPage === 1" status="primary" ghost (click)="prevPage()">
        Prev
      </button>
      <div class="margin-block-auto">
        Page <b>{{ currentPage }}</b> of <b>{{ totalPages }}</b>
      </div>
      <button nbButton size="tiny" [hidden]="currentPage === totalPages" status="primary" ghost (click)="nextPage()">
        Next
      </button>
    </div>
  </div>
</div>
