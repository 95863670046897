<div *ngIf="mappingType" class="grid-2-columns row-gap-2 column-gap-3 width-fit margin-inline-auto padding-block-auto">
  <div>Term(s)</div>
  <div>
    <textarea
      class="margin-top-1"
      #termsInput
      placeholder="Enter term(s) one per line"
      nbInput
      (input)="onTermChange()"
      cols="30rem"
      [rows]="3">
    </textarea>
    <div class="text-small" [class.text-error]="maxTermsError">
      {{ settings.terms.length }} / {{ maxTermCount }} Maximum Terms.
      <span *ngIf="maxTermsError"> Please remove terms to continue. </span>
    </div>
    <div class="margin-top-1">
      <div>Or choose <b>.csv</b> file to upload terms:</div>
      <app-file-upload (fileSelected)="onFileSelected($event)"></app-file-upload>
      <div class="text-small text-error">{{ uploadError }}</div>
    </div>
  </div>
</div>
