import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unknown-error',
  templateUrl: './unknown-error.component.html',
  styleUrls: ['./unknown-error.component.scss'],
})
export class UnknownErrorComponent {
  constructor(private router: Router) {}

  navigateHome() {
    this.router.navigate(['/']).then(() => location.reload());
  }
}
