import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IMappingPayload } from '../interfaces/i-terminology-mapping-payload';
import { ITerminologyMapping } from '../interfaces/i-mapping-status';
import { IMappingResult } from '../interfaces/i-mapping-result';
import { IAllMappings } from '../interfaces/i-all-mappings';
import { IFileUploadResponse } from '../interfaces/i-file-upload-response';
import { ITermsInputFileResponse } from '../interfaces/i-terms-input-file-response';
import { IOntology } from '../interfaces/i-ontology';
import { IOntologyFileUploadResponse, IOntologyIngestionTasks } from '../interfaces/i-ontology-file-upload-response';
import { ITmsRule, ITmsRuleAdd } from '../interfaces/i-tms-rule';
import { IOntologyTerm } from '../interfaces/i-ontology-term';
import { ISearchResult } from '../interfaces/i-search-result';
import { IGetOntologiesResult } from '../interfaces/i-get-ontologies-result';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly BASE_URL: string = environment.baseUrl;
  constructor(private http: HttpClient) {}

  fetchAllMappings(): Observable<IAllMappings> {
    return this.http.get<IAllMappings>(this.BASE_URL + 'terminology-mappings');
  }

  getOntologies(offset = 0, limit = 1000): Observable<IGetOntologiesResult> {
    return this.http.get<IGetOntologiesResult>(this.BASE_URL + 'ontologies', { params: { offset, limit } }).pipe(
      map(response => {
        response.result.forEach(ontology => {
          if (!ontology.ontology_version) {
            ontology.ontology_version = '';
          }
        });
        return response;
      })
    );
  }

  postOntology(ontology_name: string, ontology_version: string, visibility = 'private'): Observable<IOntology> {
    return this.http.post<IOntology>(this.BASE_URL + 'ontologies', {
      ontology_name,
      ontology_version,
      visibility,
    });
  }

  putOntology(
    ontology_id: string,
    ontology_name: string,
    ontology_version: string,
    visibility: string
  ): Observable<IOntology> {
    return this.http.put<IOntology>(this.BASE_URL + 'ontologies', {
      ontology_id,
      ontology_name,
      ontology_version,
      visibility,
    });
  }

  deleteOntology(id: string): Observable<unknown> {
    return this.http.delete(this.BASE_URL + 'ontologies/' + id);
  }

  uploadOwlFile(ontology_id: string, file_url_path: string): Observable<IOntologyFileUploadResponse> {
    return this.http.post<IOntologyFileUploadResponse>(this.BASE_URL + 'ontology-ingestion', {
      ontology_id,
      file_url_path,
    });
  }

  getUploadOwlFileStatus(id: string): Observable<IOntologyFileUploadResponse | undefined> {
    return this.http.get<IOntologyIngestionTasks>(this.BASE_URL + 'ontology-ingestion?id=' + id).pipe(
      map(response => {
        if (response.tasks.length === 1) {
          return response.tasks[0];
        } else {
          return undefined;
        }
      })
    );
  }

  getChildren(ontologyId: string, parentIri: string, offset: number, limit: number): Observable<ISearchResult> {
    return this.http.get<ISearchResult>(this.BASE_URL + 'ontology-data/' + ontologyId + '/children', {
      params: {
        parent_iri: parentIri,
        offset,
        limit,
      },
    });
  }

  getAncestors(ontologyId: string, term_iri: string): Observable<IOntologyTerm[][]> {
    return this.http.get<IOntologyTerm[][]>(this.BASE_URL + 'ontology-data/' + ontologyId + '/ancestors', {
      params: {
        term_iri,
      },
    });
  }

  searchTerm(
    ontologyId: string,
    query: string,
    parents_only = false,
    offset = 0,
    limit = 1000
  ): Observable<ISearchResult> {
    return this.http.get<ISearchResult>(this.BASE_URL + `ontology-data/${ontologyId}/search`, {
      params: { query, parents_only, limit, offset },
    });
  }

  fetchMappingMethodologies(): Observable<string[]> {
    return this.http.get<string[]>(this.BASE_URL + 'task/names');
  }

  fetchResultHeaders(methodology: string): Observable<string[]> {
    return this.http.get<string[]>(this.BASE_URL + 'task/result-headers', {
      params: {
        task_name: methodology,
      },
    });
  }

  uploadFile(file: File): Observable<IFileUploadResponse> {
    const formData = new FormData();
    formData.append('upload_file', file);
    return this.http.post<IFileUploadResponse>(this.BASE_URL + 'files', formData);
  }

  submit(newTask: IMappingPayload): Observable<ITerminologyMapping> {
    return this.http.post<ITerminologyMapping>(this.BASE_URL + 'terminology-mappings', newTask);
  }

  updateTask(updatedTask: ITerminologyMapping): Observable<ITerminologyMapping> {
    return this.http.put<ITerminologyMapping>(this.BASE_URL + 'terminology-mappings/' + updatedTask.id, updatedTask);
  }

  getMapping(taskId: string): Observable<ITerminologyMapping> {
    return this.http.get<ITerminologyMapping>(this.BASE_URL + 'terminology-mappings/' + taskId);
  }

  getResult(taskId: string, limit = 1000): Observable<IMappingResult> {
    return this.http.get<IMappingResult>(this.BASE_URL + 'terminology-mappings/' + taskId + '/result', {
      params: { limit },
    });
  }

  getTaskRequest(taskId: string): Observable<IMappingPayload> {
    return this.http.get<IMappingPayload>(this.BASE_URL + 'terminology-mappings/' + taskId + '/task-request').pipe(
      map(request => {
        const termsList: { term: string; ontologies: string[] }[] = [];
        request.params.terms?.forEach(term => {
          if (typeof term === 'string') {
            const normalizedTerm = {
              term,
              ontologies: request.params.ontologies ? request.params.ontologies : [],
            };
            termsList.push(normalizedTerm);
          } else {
            termsList.push(term);
          }
        });
        request.params.terms = termsList;
        return request;
      })
    );
  }

  getTaskRequestInputFileMetadata(taskId: string): Observable<ITermsInputFileResponse> {
    return this.http.get<ITermsInputFileResponse>(
      this.BASE_URL + 'terminology-mappings/' + taskId + '/task-request/terms-input-file'
    );
  }

  cancelTask(taskId: string): Observable<ITerminologyMapping> {
    return this.http.post<ITerminologyMapping>(this.BASE_URL + 'terminology-mappings/' + taskId + '/cancel', {});
  }

  download(taskId: string, fileName: string, headers: string[] = [], result_type = 'csv'): void {
    switch (result_type) {
      case 'json': {
        const sub$ = this.getResult(taskId, 999999999999).subscribe(result => {
          delete result.current_limit;
          delete result.current_offset;
          this.createFile(JSON.stringify(result), fileName, result_type);
          sub$.unsubscribe();
        });
        break;
      }
      default: {
        this.downloadFile(fileName, this.BASE_URL + 'terminology-mappings/' + taskId + '/download', result_type, {
          headers,
          result_type,
        });
      }
    }
  }

  downloadTermsMappedFile(taskId: string, fileName: string, headers: string[] = []) {
    this.downloadFile(fileName, this.BASE_URL + 'terminology-mappings/' + taskId + '/terms-mapped-input/csv', 'csv', {
      headers,
    });
  }

  getMappingRules(ontology_id: string): Observable<ITmsRule[]> {
    return this.http.get<ITmsRule[]>(this.BASE_URL + 'mapping-rules', { params: { ontology_id } });
  }

  addMappingRule(newRule: ITmsRuleAdd): Observable<ITmsRule> {
    return this.http.post<ITmsRule>(this.BASE_URL + 'mapping-rules', newRule);
  }

  deleteMappingRule(tmsRule: ITmsRule): Observable<ITmsRule> {
    return this.http.delete<ITmsRule>(this.BASE_URL + 'mapping-rules/' + tmsRule.tms_rule_id);
  }

  private downloadFile(
    fileName: string,
    url: string,
    fileType: string,
    params?:
      | HttpParams
      | {
          [param: string]: string | number | boolean | readonly (string | number | boolean)[];
        }
      | undefined
  ) {
    const sub$ = this.http
      .get(url, {
        responseType: 'blob',
        params,
      })
      .subscribe(data => {
        this.createFile(data, fileName, fileType);
        sub$.unsubscribe();
      });
  }

  private createFile(data: BlobPart, fileName: string, fileType: string) {
    const blob = new Blob([data], { type: 'application/' + fileType });
    const downloadURL = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = fileName + '.' + fileType;
    link.click();
  }
}
