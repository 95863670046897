import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Observable, concatMap, throwError } from 'rxjs';
import { IOntology } from 'src/app/interfaces/i-ontology';
import { ITmsRule, ITmsRuleAdd } from 'src/app/interfaces/i-tms-rule';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-add-mapping-rule-dialog',
  templateUrl: './add-mapping-rule-dialog.component.html',
  styleUrls: ['./add-mapping-rule-dialog.component.scss'],
})
export class AddMappingRuleDialogComponent implements OnInit {
  // Input can either be a newRule object or
  // ontology object.
  @Input() newRule?: ITmsRuleAdd;

  // If input is ontology, then the user must
  // manually enter the term's curie in the dialog box
  // to create a new rule
  @Input() ontology?: IOntology;

  loading = false;
  error?: string;

  newTerm?: string;
  newLabelOrCurie?: string;
  canAddRule = false;

  constructor(
    private dialogRef: NbDialogRef<unknown>,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    this.verifyInputs();
  }

  close(newRule?: ITmsRule) {
    this.dialogRef.close({ ruleAdded: newRule });
  }

  addRule() {
    this.loading = true;
    let apiEndpoint: Observable<ITmsRule>;
    if (this.newRule) {
      apiEndpoint = this.api.addMappingRule(this.newRule);
    } else if (this.ontology && this.canAddRule) {
      apiEndpoint = this.searchTermAndAddRule();
    } else {
      this.loading = false;
      this.error = 'Must specify either newRule or ontology to add a new rule';
      this.canAddRule = false;
      return;
    }
    apiEndpoint.subscribe({
      next: newRuleAdded => {
        this.loading = false;
        this.close(newRuleAdded);
      },
      error: err => {
        this.error = JSON.stringify(err.error.detail);
        this.loading = false;
      },
    });
  }

  private searchTermAndAddRule(): Observable<ITmsRule> {
    return this.api.searchTerm(this.ontology!.ontology_id, this.newLabelOrCurie!).pipe(
      concatMap(searchResult => {
        if (searchResult.total_results === 0) {
          return throwError(() => {
            return new HttpErrorResponse({
              status: 404,
              error: { detail: 'Ontology Term not found' },
            });
          });
        }
        const terms = searchResult.result.filter(
          r => r.label === this.newLabelOrCurie || r.curie === this.newLabelOrCurie
        );
        if (terms.length > 0) {
          const term = terms[0];
          return this.api.addMappingRule({
            label: term.label,
            term_id: term.term_id,
            ontology_name: this.ontology!.ontology_name,
            query_term: this.newTerm!,
          });
        }
        return throwError(() => {
          return new HttpErrorResponse({
            status: 404,
            error: { detail: 'Ontology Term not found' },
          });
        });
      })
    );
  }

  verifyInputs() {
    this.error = undefined;
    this.canAddRule =
      this.newRule !== undefined ||
      (this.newTerm !== undefined &&
        this.newTerm.length > 0 &&
        this.newLabelOrCurie !== undefined &&
        this.newLabelOrCurie.length > 0);
  }
}
