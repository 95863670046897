import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewMappingComponent } from './pages/new-mapping/new-mapping.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { UnknownErrorComponent } from './pages/unknown-error/unknown-error.component';
import { ErrorForbiddenComponent } from './pages/error-forbidden/error-forbidden.component';
import { ManageOntologiesComponent } from './pages/manage-ontologies/manage-ontologies.component';
import { ViewOntologyComponent } from './pages/view-ontology/view-ontology.component';
import { OntologyMappingRulesComponent } from './pages/ontology-mapping-rules/ontology-mapping-rules.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, title: 'TMS | Dashboard' },
  { path: 'new-mapping', redirectTo: 'new-mapping/', pathMatch: 'full', title: 'TMS | New Mapping' },
  { path: 'new-mapping/:id', component: NewMappingComponent, title: 'TMS | New Mapping' },
  { path: 'manage-ontologies', component: ManageOntologiesComponent, title: 'TMS | Ontologies' },
  { path: 'manage-ontologies/:id', component: ViewOntologyComponent, title: 'TMS | View Ontology' },
  {
    path: 'manage-ontologies/:id/mapping-rules',
    component: OntologyMappingRulesComponent,
    title: 'TMS | Ontology Mapping Rules',
  },
  { path: 'error-forbidden', component: ErrorForbiddenComponent, title: 'TMS | Error - Forbidden' },
  { path: 'unknown-error', component: UnknownErrorComponent, title: 'TMS | Error - Unknown' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
