import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OntologyService } from 'src/app/services/ontology.service';
import * as echarts from 'echarts';
import { Subscription, firstValueFrom } from 'rxjs';
import { IOntology } from 'src/app/interfaces/i-ontology';
import { ITreeElement } from 'src/app/interfaces/i-tree-element';
import { IOntologyTerm } from 'src/app/interfaces/i-ontology-term';
import { ISearchResult } from 'src/app/interfaces/i-search-result';

@Component({
  selector: 'app-view-ontology',
  templateUrl: './view-ontology.component.html',
  styleUrls: ['./view-ontology.component.scss'],
})
export class ViewOntologyComponent implements AfterViewInit, OnDestroy {
  public loading = true;
  public ontology!: IOntology;

  public searchQuery: string | undefined;
  public searchSuggestions: string[] = [];
  public loadingSearchSuggestions = false;
  public searchResult: ISearchResult | undefined;

  public ancestryPaths: IOntologyTerm[][] | undefined;
  public focusTerm: IOntologyTerm | undefined;
  public focusTermError = '';

  public searchError = '';
  private data!: ITreeElement;
  private myChart: echarts.ECharts | undefined;
  private sampleElement: ITreeElement = {
    id: 'sampleId',
    collapsed: true,
    name: '',
    children: [],
  };
  private subs: Subscription[] = [];
  private readonly MAX_CHILDREN_VIEW = 25;
  readonly NEXT_TERMS_ID = '-next-terms';
  readonly PREV_TERMS_ID = '-prev-terms';

  readonly SEARCH_RESULTS_LIMIT = 5;

  constructor(
    public ontologyService: OntologyService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}

  private exitPage() {
    this.router.navigate(['manage-ontologies']);
  }

  ngAfterViewInit() {
    this.loading = true;
    const sub = this.ontologyService.initialized$.subscribe({
      next: async initialized => {
        if (initialized) {
          const currentId = this.route.snapshot.paramMap.get('id');
          if (currentId === null) {
            this.exitPage();
            return;
          }
          const ontology = this.ontologyService.getOntology(currentId);
          if (ontology === undefined) {
            this.exitPage();
            return;
          }
          this.ontology = ontology;
          this.initializeDataTree();
          await this.fetchChildren(this.data);
          this.loading = false;
        }
      },
      error: () => {
        this.loading = false;
        this.exitPage();
      },
    });
    this.cdr.detectChanges();
    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  onSearchTextChange(newText: string) {
    this.searchError = '';
    this.focusTermError = '';
    if (newText.length === 0) {
      this.searchSuggestions = [];
    } else {
      if (!this.loadingSearchSuggestions) {
        this.loadingSearchSuggestions = true;
        const sub = this.ontologyService.searchTerm(this.ontology.ontology_id, newText, 0, 5).subscribe({
          next: result => {
            sub.unsubscribe();
            this.searchSuggestions = result.result.map(r => r.label);
            this.loadingSearchSuggestions = false;
          },
          error: () => {
            sub.unsubscribe();
            this.loadingSearchSuggestions = false;
          },
        });
      }
    }
  }

  searchTerm(term: string, offset = 0) {
    this.searchSuggestions = [];
    this.searchQuery = term;
    this.loading = true;
    const sub = this.ontologyService
      .searchTerm(this.ontology.ontology_id, term, offset, this.SEARCH_RESULTS_LIMIT)
      .subscribe({
        next: response => {
          if (response.total_results > 1) {
            this.showSearchResults(response);
          } else if (response.total_results === 1) {
            const isExactMatch =
              response.result[0].label.toLowerCase() === term.toLowerCase() ||
              response.result[0].curie?.toLowerCase() === term.toLowerCase();
            if (isExactMatch) {
              this.getAncestry(response.result[0]);
            } else {
              this.showSearchResults(response);
            }
          } else {
            this.searchError = `⚠️ Could not find '${term}' in this ontology. Please try again.`;
            this.loading = false;
          }
        },
        error: err => {
          if (err.status === 404) {
            this.searchError = `⚠️ Could not find '${term}' in this ontology. Please try again.`;
          } else {
            this.searchError = '❌ Unknown Error. Please try again';
          }
          console.error('Could not find term', err);
          this.loading = false;
        },
      });
    this.subs.push(sub);
  }

  public showSearchPage(showPage: { queryTerm: string; pageNumber: number }) {
    const offset = (showPage.pageNumber - 1) * this.SEARCH_RESULTS_LIMIT;
    this.searchTerm(showPage.queryTerm, offset);
  }

  public getAncestry(term: IOntologyTerm) {
    this.loading = true;
    this.focusTerm = term;
    this.focusTermError = '';
    const sub = this.ontologyService.getAncestors(this.ontology.ontology_id, term.iri).subscribe({
      next: ancestors => {
        sub.unsubscribe();
        if (ancestors.length > 0) {
          this.showAncestryPaths(ancestors);
        } else {
          console.error('Unreachable code. No ancestor paths returned for term. Investigate as bug', term);
        }
      },
      error: error => {
        this.loading = false;
        if (error?.status === 422) {
          this.focusTermError = '⚠️ Irregular Ontology. Cannot display ancestry for "' + this.focusTerm?.label + '".';
        } else {
          this.focusTermError = '❌ Error fetching Ancestry for "' + this.focusTerm?.label + '". Please try again';
        }
      },
    });
  }

  public reset() {
    this.ancestryPaths = undefined;
    this.searchResult = undefined;
    this.searchError = '';
    this.focusTermError = '';
  }

  private showAncestryPaths(ancestors: IOntologyTerm[][]) {
    this.searchResult = undefined;
    this.ancestryPaths = ancestors;
    this.loading = false;
  }

  public showPath(path: IOntologyTerm[]) {
    this.ancestryPaths = undefined;
    this.searchResult = undefined;
    const searchTerm = path[path.length - 1];
    let ancestorTree: ITreeElement = this.sampleElement;
    path.reverse().forEach((a, index) => {
      let currentChildren: ITreeElement[] = [];
      if (index === 0) {
        currentChildren = a.num_children ? [this.sampleElement] : [];
      } else {
        currentChildren = [ancestorTree];
      }
      ancestorTree = {
        id: a.iri,
        name: a.label,
        collapsed: a.iri === searchTerm.iri ? true : false,
        curie: a.curie,
        children: currentChildren,
      };
    });
    this.initializeDataTree(ancestorTree);
    this.loading = false;
  }

  private showSearchResults(results: ISearchResult) {
    this.searchResult = results;
    this.ancestryPaths = undefined;
    this.loading = false;
  }

  private initializeDataTree(preloadedChildren?: ITreeElement) {
    this.data = {
      name: this.ontology.ontology_name,
      collapsed: preloadedChildren ? false : true,
      id: '',
      children: [preloadedChildren ? preloadedChildren : this.sampleElement],
      allChildrenCount: 1,
    };

    // Create the echarts instance
    if (!this.myChart) {
      this.myChart = echarts.init(document.getElementById('chart'));
    }
    this.updateChart();
    this.myChart.on('click', params => {
      const element = params.data as ITreeElement;
      if (element.collapsed) {
        // Collapse clicked element and dynamically fetch children
        element.collapsed = true;
        this.updateChart();
        if (element.id.includes(this.NEXT_TERMS_ID) || element.id.includes(this.PREV_TERMS_ID)) {
          this.navigationElementClicked(element);
        } else {
          this.fetchChildren(element);
        }
      } else {
        this.collapseChildren(element);
      }
      return false;
    });
  }

  private updateChart() {
    if (this.myChart) {
      const option: echarts.EChartsOption = {
        tooltip: {
          show: true,
          hideDelay: 3000,
          trigger: 'item',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          formatter: function (params: any) {
            const data = params.data as ITreeElement;
            if (data?.id?.length > 0 && !data.id.includes('-next-terms') && !data.id.includes('-prev-terms')) {
              return `
              Term: <b>${data.name}</b><br/>
              Curie: <b>${data.curie}</b><br/>
              Iri: <b>${data.id}</b>`;
            }
            return params.name;
          },
          position: [0, '85%'],
        },
        series: [
          {
            type: 'tree',
            roam: true,

            data: [this.data],

            top: '1%',
            left: '7%',
            bottom: '1%',
            right: '20%',

            symbolSize: 12,

            label: {
              position: 'left',
              verticalAlign: 'middle',
              align: 'right',
              fontSize: 12,
              fontWeight: 500,
            },

            itemStyle: {
              color: '#f58021',
            },

            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left',
              },
              itemStyle: {
                color: '#f58021',
              },
            },

            emphasis: {
              focus: 'relative',
            },

            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750,
          },
        ],
      };
      // Draw the chart
      this.myChart.setOption(option, true, true);
    } else {
      console.error('No chart found in this page');
    }
  }

  private async navigationElementClicked(element: ITreeElement) {
    let index = 0;
    if (element.id.includes(this.NEXT_TERMS_ID)) {
      index = +element.id.split('-')[0] + this.MAX_CHILDREN_VIEW;
    } else if (element.id.includes(this.PREV_TERMS_ID)) {
      index = +element.id.split('-')[0] - this.MAX_CHILDREN_VIEW;
    } else {
      console.error('Cannot show new page if element clicked is not a navigation element');
      return;
    }
    const currentLevel = this.getParent(this.data, element.id);
    if (currentLevel === undefined) {
      console.error(`Cannot find parent with child IRI=${element.id} in data tree`, this.data);
      return;
    }
    if (!currentLevel.allChildrenCount || currentLevel.allChildrenCount < index) {
      console.error(`Cannot find children count for this term for index=${index}. Needs investigation`);
      console.error('currentLEevel', currentLevel);
      return;
    }
    await this.fetchChildren(currentLevel, index);
  }

  private addNavPrevButton(currentLevel: ITreeElement, index: number) {
    const allChildrenCount = currentLevel.allChildrenCount;
    if (allChildrenCount === undefined) {
      console.error('Cannot add Nav PREVIOUS button because allChildrenCount is undefined:', currentLevel);
      return;
    }
    if (index !== 0) {
      currentLevel.children.push({
        name: '⬆️ View terms ' + (index - this.MAX_CHILDREN_VIEW + 1) + '-' + index + ' of ' + allChildrenCount,
        id: index + this.PREV_TERMS_ID + '-' + currentLevel.id,
        collapsed: true,
        children: [this.sampleElement],
      });
    }
  }

  private addNavNextButton(currentLevel: ITreeElement, index: number) {
    const allChildrenCount = currentLevel.allChildrenCount;
    if (allChildrenCount === undefined) {
      console.error('Cannot add Nav NEXT buttons because allChildrenCount is undefined:', currentLevel);
      return;
    }
    if (index + this.MAX_CHILDREN_VIEW < allChildrenCount) {
      currentLevel.children.push({
        name:
          '⬇️ View terms ' +
          (index + this.MAX_CHILDREN_VIEW + 1) +
          '-' +
          (index + 2 * this.MAX_CHILDREN_VIEW < allChildrenCount
            ? index + 2 * this.MAX_CHILDREN_VIEW
            : allChildrenCount) +
          ' of ' +
          allChildrenCount,
        id: index + this.NEXT_TERMS_ID + '-' + currentLevel.id,
        collapsed: true,
        children: [this.sampleElement],
      });
    }
  }

  private async fetchChildren(element: ITreeElement, offset = 0) {
    if (element.children.length === 0) {
      return;
    }
    this.loading = true;
    const currentLevel = this.searchTree(this.data, element.id);
    if (currentLevel === undefined) {
      console.error(`Cannot find IRI=${element.id} in data tree`, this.data);
      return;
    }
    const children: ISearchResult = await firstValueFrom(
      this.ontologyService.getChildrenOfTerm(this.ontology.ontology_id, currentLevel.id, offset, this.MAX_CHILDREN_VIEW)
    );
    currentLevel.children = [];
    this.addNavPrevButton(currentLevel, offset);
    currentLevel.allChildrenCount = children.total_results;
    children.result.forEach(child => {
      const childElement: ITreeElement = {
        name: child.label,
        curie: child.curie,
        id: child.iri,
        collapsed: true,
        children: child.num_children && child.num_children > 0 ? [this.sampleElement] : [],
        allChildrenCount: child.num_children ? child.num_children : 0,
      };
      currentLevel.children.push(childElement);
    });
    this.addNavNextButton(currentLevel, offset);
    currentLevel.collapsed = false;
    this.updateChart();
    this.loading = false;
  }

  private searchTree(element: ITreeElement, matchingId: string) {
    if (element.id === matchingId) {
      return element;
    } else {
      let result: ITreeElement | undefined;
      if (element.children) {
        for (const child of element.children) {
          result = this.searchTree(child, matchingId);
          if (result !== undefined) {
            break;
          }
        }
      }
      return result;
    }
  }

  private getParent(element: ITreeElement, childId: string): ITreeElement | undefined {
    if (element.children.findIndex(e => e.id === childId) >= 0) {
      return element;
    } else {
      let result: ITreeElement | undefined;
      if (element.children) {
        for (const child of element.children) {
          result = this.getParent(child, childId);
          if (result !== undefined) {
            break;
          }
        }
      }
      return result;
    }
  }

  private collapseChildren(element: ITreeElement) {
    const dataElement = this.searchTree(this.data, element.id);
    if (dataElement) {
      dataElement.collapsed = true;
      this.updateChart();
    } else {
      console.error('Cannot find element in current tree', element);
    }
  }
}
