import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-input-with-suggestions',
  templateUrl: './input-with-suggestions.component.html',
  styleUrls: ['./input-with-suggestions.component.scss'],
})
export class InputWithSuggestionsComponent implements OnChanges {
  @Input() icon?: string;
  @Input() placeholder = '';
  @Input() options: string[] = [];
  @Input() submitLabel = '';
  @Output() textChange = new EventEmitter<string>();
  @Output() submitClicked = new EventEmitter<string>();
  @Output() suggestionSelected = new EventEmitter<string>();
  filteredOptions: string[] = [];

  @ViewChild('autoInput') input: ElementRef | undefined;

  private suggestionRecentlySelected = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['options'] && this.input) {
      const newValue: string = this.input.nativeElement.value;
      this.filteredOptions = this.filter(newValue);
    }
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(optionValue => optionValue.toLowerCase().includes(filterValue));
  }

  inputKeyUp(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (this.suggestionRecentlySelected == false) {
        this.submit();
      }
    }
  }

  onChange() {
    if (this.input) {
      const newValue: string = this.input.nativeElement.value;
      this.textChange.emit(newValue.trim());
    }
  }

  onSelectionChange(value: string) {
    // Temporarily disable submit() by clicking ENTER when the action is selecting a suggested term
    this.suggestionRecentlySelected = true;
    setTimeout(() => (this.suggestionRecentlySelected = false), 500);

    this.filteredOptions = this.filter(value);
    this.suggestionSelected.emit(value);
  }

  submit() {
    const value = this.input?.nativeElement.value;
    if (value && value.length > 0) {
      this.submitClicked.emit(this.input?.nativeElement.value.trim());
    }
  }
}
