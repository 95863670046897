import { Component } from '@angular/core';

@Component({
  selector: 'app-error-forbidden',
  templateUrl: './error-forbidden.component.html',
  styleUrls: ['./error-forbidden.component.scss'],
})
export class ErrorForbiddenComponent {
  showFeedbackForm() {
    const ifr = document.getElementById('lightbox-233614526310144');
    if (ifr) {
      ifr.click();
    }
  }
}
