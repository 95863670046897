<div class="width-5 margin-inline-auto">
  <div class="flex-between">
    <div class="header-2 width-fit">
      <span *ngIf="ancestryPaths.length > 1">
        Found <b>{{ ancestryPaths.length }}</b>
      </span>
      <span>
        Ancestry Path{{ ancestryPaths.length > 1 ? 's' : '' }} for <b>{{ queryTerm }}</b>
      </span>
    </div>
  </div>
  <nb-card *ngFor="let path of sortedAncestryPaths; let index = index">
    <nb-card-body>
      <div class="flex-wrap align-items-center">
        <div
          *ngFor="let result of path; let last = last; let first = first; let termIdx = index"
          class="flex-x align-items-center">
          <ng-container *ngIf="first">
            <div class="width-fit margin-inline-1">
              {{ ontologyName }}
            </div>
            <nb-icon icon="arrow-right"></nb-icon>
          </ng-container>
          <div class="width-fit margin-inline-1" [class.header-1]="last">
            {{ result.label }}
          </div>
          <nb-icon *ngIf="!last && termIdx !== lastCommonAncestorIdx" icon="arrow-right"></nb-icon>
          <div *ngIf="termIdx === lastCommonAncestorIdx" class="text-center">
            <img width="20px" src="assets/split-icon.svg" />
          </div>
        </div>
      </div>
      <div class="margin-top-1 flex-between">
        <button nbButton status="primary" (click)="pathSelected.emit(path)" shape="round" size="small">
          Show On Graph<nb-icon icon="arrowhead-right-outline"></nb-icon>
        </button>
        <div *ngIf="ancestryPaths.length > 1" class="text-small margin-top-1">
          {{ index + 1 }} / {{ ancestryPaths.length }}
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</div>
