import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, switchMap, throwError } from 'rxjs';
import { KeycloakService } from '../services/keycloak.service';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private kcService: KeycloakService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.kcService.getToken() && !this.kcService.userHasUIAccess()) {
      this.showAccessForbidden();
      return throwError(
        () =>
          new HttpErrorResponse({
            status: 403,
            statusText: 'User does not have Access_UI role. Forbidden.',
          })
      );
    }
    request = this.kcService.addAuthHeader(request);
    return next.handle(request).pipe(
      catchError(error => {
        return this.handleErrors(error, request, next);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleErrors(
    error: HttpErrorResponse,
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (error.status === 0 || error.status >= 500) {
      // Unknown Error from server
      this.showErrorPage();
      return throwError(() => error);
    } else if (error.status === 401) {
      return this.kcService.refreshToken().pipe(
        switchMap(() => {
          request = this.kcService.addAuthHeader(request);
          return next.handle(request);
        }),
        catchError(err => {
          this.showErrorPage();
          return throwError(() => err);
        })
      );
    } else {
      // 400 User Error. Throw normally
      return throwError(() => error);
    }
  }

  private showAccessForbidden() {
    this.router.navigate(['error-forbidden']);
  }

  private showErrorPage() {
    this.router.navigate(['unknown-error'], { skipLocationChange: true });
  }
}
