<nb-card [nbSpinner]="loading" nbSpinnerStatus="primary" class="border-radius-1">
  <div class="white-fill padding-1 border-radius-1">
    <div class="header-1">{{ ontology ? 'Edit' : 'Create New' }} Ontology</div>
    <div class="grid-2-columns column-gap-1 row-gap-1 margin-top-2">
      <div class="margin-block-auto">Ontology Name</div>
      <input #ontologyName nbInput (input)="updateValues()" />

      <div class="margin-block-auto">Version</div>
      <input #ontologyVersion nbInput (input)="updateValues()" />

      <div class="margin-block-auto">Visibility</div>
      <nb-radio-group [(value)]="visibility" (valueChange)="updateValues()" class="flex-x">
        <nb-radio value="private">private</nb-radio>
        <nb-radio *ngIf="keycloakService.canManageOntologies()" value="public">public</nb-radio>
      </nb-radio-group>
    </div>
    <div *ngIf="nameError" class="margin-block-1 text-error text-center">
      <b>{{ name }}</b> is already in use.<br />Please select a different name.
    </div>
    <div *ngIf="errorMsg" class="margin-block-1 text-error text-center">
      Error: <b>{{ errorMsg }}</b>
    </div>
    <div class="flex-x column-gap-2 margin-inline-auto width-fit" [class.margin-top-2]="!nameError">
      <button nbButton outline status="primary" (click)="close()" shape="round">Cancel</button>
      <button
        nbButton
        status="primary"
        (click)="createOrUpdate()"
        shape="round"
        [disabled]="name.length === 0 || version.length === 0">
        {{ ontology ? 'Update' : 'Create' }}
      </button>
    </div>
  </div>
</nb-card>
