import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { IAllMappings } from 'src/app/interfaces/i-all-mappings';
import { ApiService } from 'src/app/services/api.service';
import { DownloadDialogComponent } from '../download-dialog/download-dialog.component';
import { ITerminologyMapping } from 'src/app/interfaces/i-mapping-status';
import { IGridEditEventArgs } from '@infragistics/igniteui-angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
})
export class TaskListComponent implements OnInit, OnDestroy {
  loading = false;
  taskList: IAllMappings | undefined;
  private subscriptions: Subscription[] = [];

  constructor(
    private api: ApiService,
    private dialogService: NbDialogService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.api.fetchAllMappings().subscribe({
      next: taskList => {
        this.taskList = taskList;
      },
      complete: () => (this.loading = false),
    });
  }

  download(mapping: ITerminologyMapping) {
    this.dialogService.open(DownloadDialogComponent, {
      context: {
        taskId: mapping.id,
        inputType: mapping.terms_input_type,
        mappingMethodology: mapping.task_name,
        totalTerms: mapping.total_terms,
        fileName: mapping.label,
      },
    });
  }

  valueUpdated(event: IGridEditEventArgs) {
    const updatedMapping = event.rowData;
    if (event.column?.field === 'label') {
      updatedMapping.label = event.newValue;
      const sub = this.api.updateTask(updatedMapping).subscribe();
      this.subscriptions.push(sub);
    } else {
      console.error('NOT IMPLEMENTED. Unable to update field:' + event.column?.field);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
